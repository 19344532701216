<template>
  <div id="publicOffer">
    <mainMenu></mainMenu>
    <div class="wrapper">
      <div class="document">
        <p class="document_sub-heading">
          ПРЕДЛОЖЕНИЕ
          <br />
          (Публичная оферта)
          <br />
          заключить «Договор о предоставлении услуг полного доступа к системе«Dentabula»
          <br />
          (Редакция № 1 от «11» мая 2018 года)
        </p>
        <p class="document_sub-p">
          <b>Физическое лицо-предприниматель Погарский Антон Юрьевич</b> (далее - <b>Исполнитель</b>), адресует эту
          оферту - предложение заключить «Договор о предоставлении услуг полного доступа к системе «Dentabula» (далее по
          тексту - <b>Договор</b>) любому физическому лицу (гражданину Украины, иностранцу, лицу без гражданства и др.),
          которое желает иметь полный доступ к системе «Dentabula».
        </p>
        <p class="document_sub-p">
          Ознакомиться с условиями Договора можно по
          <router-link :to="{ name: 'contract' }">данной ссылке</router-link>.
        </p>
        <p class="document_sub-p">
          Изложенная ниже информация, помимо прочего, в том числе, но не исключительно, представляет собой
          соответствующее электронное сообщение. Предложенная в изложенной ниже редакции оферта начинает действовать с
          момента ее размещения на официальном сайте системы «Dentabula» в сети Интернет по адресу
          <a href="https://www.dentabula.com">https://www.dentabula.com</a> , и действует до момента ее отзыва или
          изменения Исполнителем, информация о чем публикуется на официальном сайте системы «Dentabula». Оферта может
          быть отозвана Исполнителем в любой момент и в одностороннем порядке. Исполнитель оставляет за собой право
          вносить изменения в условия данной оферты в любой момент по своему усмотрению. В случае внесения Исполнителем
          таких изменений, они вступают в силу с момента их опубликования на официальном сайте системы «Dentabula».
        </p>
        <p class="document_sub-p">
          <b>ВНИМАНИЕ!</b> Исполнитель разъясняет следующее: для того чтобы заключить Договор физическое лицо должно
          идентифицироваться в системе «Dentabula» путем соответствующей регистрации на ее официальном сайте и
          осуществить акцепт данного предложения, то есть дать согласие на заключение Договора на условиях, изложенных в
          данной оферте и в тексте самого Договора. Акцептом настоящей оферты (ПРЕДЛОЖЕНИЯ) является ПРИНЯТИЕ УСЛОВИЙ
          ДОГОВОРА ПУТЕМ оплаты стоимости Услуг. С момента оплаты Услуг физическое лицо автоматически принимает условия
          Договора, и он считается заключенным без подписания в каждом отдельном случае. Вышеизложенным Исполнитель
          выражает намерение считать себя обязанным в случае принятия предложения заключить Договор.
        </p>
        <p class="document_sub-p">
          При необходимости, по усмотрению Исполнителя и по его желанию, не вступая в противоречие с условиями данной
          оферты, Исполнитель может заключить с физическим лицом Договор в форме письменного двухстороннего документа.
        </p>
        <p class="document_sub-p">
          Для возможности заключения Договора и выполнения обязательств по нему, физическое лицо, желающее иметь полный
          доступ к системе «Dentabula», должно путем заполнения соответствующей электронной формы на официальном сайте
          системы «Dentabula» предоставить Исполнителю следующие сведения о себе: - уникальное имя (логин); - адрес
          своей электронной почты.
        </p>
        <p class="document_sub-p">
          <b>ВНИМАНИЕ!</b> Для корректной работы системы «Dentabula», физическое лицо, желающее иметь к ней полный
          доступ, должно самостоятельно обеспечить себя следующей аппаратно-программной средой:
        </p>
        <p class="document_sub-list">
          <b>- операционная система:</b> Windows XP или выше, Linux, Mac OS Х 10.0 или выше;
        </p>
        <p class="document_sub-list"><b>- процессор:</b> 900 MHz или выше;</p>
        <p class="document_sub-list"><b>- оперативная память:</b> 1 Gb или выше;</p>
        <p class="document_sub-list"><b>- видео-карта:</b> VGA 800x600 или выше;</p>
        <p class="document_sub-list"><b>- версия OpenGL:</b> 2.0 или выше;</p>
        <p class="document_sub-list">
          <b>- браузер:</b> IE v.11 или выше, Edge v.15 или выше, Firefox v.56 или выше, Chrome v.49 или выше, Safari
          v.10 или выше, iOS Safari v.10 или выше.
        </p>
        <p class="document_sub-list">
          <b>- сеть Интернет:</b> непрерывный и бесперебойный доступ к сети Интернет со скоростью передачи данных не
          менее 512 kb/s.
        </p>
        <p class="document_sub-p">
          Если физическое лицо не согласно с условиями данного предложения (оферты), и условиями Договора, Исполнитель
          просит такое физическое лицо не акцептовать их (то есть не принимать), и не совершать оплату Услуг полного
          доступа к системе «Dentabula». В случае акцепта физическим лицом (принятия) условий Договора путем
          осуществления оплаты Услуг полного доступа к системе «Dentabula», это означает, что такое физическое лицо
          полностью ознакомлено с условиями Договора, понимает и принимает их, а также соглашается с ними.
        </p>
        <p class="document_sub-p">
          <b>ВНИМАНИЕ!</b> Перед заключением Договора, Исполнитель предоставляет физическому лицу, желающему иметь
          полный доступ к системе «Dentabula», следующую информацию:
        </p>
        <p class="document_sub-list">
          <b>- наименование Исполнителя:</b> физическое лицо-предприниматель Погарский Антон Юрьевич;
        </p>
        <p class="document_sub-list">
          <b
            >- дата и номер записи в Едином государственном реестре юридических лиц, физических лиц- предпринимателей и
            общественных формирований:</b
          >
          28.03.2018 года, № 21030000000096301;
        </p>
        <p class="document_sub-list">
          <b>- местонахождение Исполнителя:</b> 69600, Украина, Запорожская область, город Запорожье, Коммунарский
          район, ул. Реконструктивная, дом 3,
        </p>
        <p class="document_sub-list">
          <b
            >- порядок принятия претензий: претензии принимаются в письменном виде, изложенные на украинском, английском
            или русском языках, путем их направления на следующий адрес электронной почты Исполнителя:</b
          >
          dentabula.AP@gmail.com. Претензия рассматривается Исполнителем в течение 30 календарных дней с момента ее
          получения. В случае, когда претензия надумана и/или необоснованна, Исполнитель вправе оставить ее без
          рассмотрения и/или без ответа;
        </p>
        <p class="document_sub-list">
          <b>- основные характеристики Услуги:</b> доступ физического лица ко всем элементам системы «Dentabula», с
          возможностью загрузки, обработки, просмотра и вывода соответствующих специализированных компьютерных файлов, в
          пределах предоставленного такой системой функционала;
        </p>
        <p class="document_sub-list">
          <b>- цена (стоимость) Услуг:</b> стоимость Услуг по Договору указана на официальном сайте системы «Dentabula»,
          по адресу: <a href="https://www.dentabula.com">https://www.dentabula.com</a>;
        </p>
        <p class="document_sub-list"><b>- условия оплаты:</b> определены в условиях Договора;</p>
        <p class="document_sub-list">
          <b>- гарантийные обязательства:</b> любые гарантии и/или гарантийные обязательства со стороны Исполнителя не
          предоставляются и/или не устанавливаются;
        </p>
        <p class="document_sub-list"><b>- условия исполнения Договора:</b> определены в условиях Договора;</p>
        <p class="document_sub-list">
          <b>- минимальная продолжительность Договора:</b> не ограничена, Договор продолжается с момента его заключения
          и до момента его расторжения любой стороной на определенных в его редакции условиях;
        </p>
        <p class="document_sub-list"><b>- период принятия предложений:</b> неограничен;</p>
        <p class="document_sub-list"><b>- порядок расторжения Договора:</b> определен в условиях Договора.</p>
      </div>
      <div class="document">
        <p class="document_sub-heading">
          ПРОПОЗИЦІЯ
          <br />
          (ПУБЛІЧНА ОФЕРТА)
          <br />
          укласти «Договір про надання послуг з повного доступу до системи «Dentabula»
          <br />
          (редакція № 1 від «11» травня 2018 року)
        </p>
        <p class="document_sub-p">
          <b>Фізична особа-підприємець Погарський Антон Юрійович</b> (далі по тексту – <b>Виконавець</b>), адресує дану
          оферту – пропозицію укласти «Договір про надання послуг з повного доступу до системи «Dentabula» (далі по
          тексту – <b>Договір</b>) будь-якій фізичній особі (громадянину України, іноземцю, особі без громадянства, та
          ін.), яка бажає мати повний доступ до системи «Dentabula».
        </p>
        <p class="document_sub-p">
          Ознайомитись з умовами Договору можна за
          <router-link :to="{ name: 'contract' }">даним посиланням</router-link>.
        </p>
        <p class="document_sub-p">
          Викладена нижче інформація, крім іншого, в тому числі, але не виключно, являє собою відповідне електронне
          повідомлення.
        </p>
        <p class="document_sub-p">
          Оферта у викладеній нижче редакції починає діяти з моменту її розміщення на офіційному сайті системи
          «Dentabula» в мережі Інтернет за адресою <a href="https://www.dentabula.com">https://www.dentabula.com</a>, і
          діє до моменту її відкликання (відзиву) або зміни Виконавцем, інформація про що публікується на офіційному
          сайті системи «Dentabula». Оферта може бути відкликана Виконавцем в будь-який момент та в односторонньому
          порядку. Виконавець залишає за собою право вносити зміни до умов даної оферти в будь-який момент на свій
          розсуд. У разі внесення Виконавцем таких змін, вони вступають в силу з моменту їх опублікування на офіційному
          сайті системи «Dentabula».
        </p>
        <p class="document_sub-p">
          <b>УВАГА!</b> Виконавець роз’яснює наступне: для того щоб укласти Договір фізична особа повинна
          ідентифікуватися в системі «Dentabula» шляхом відповідної реєстрації на її офіційному сайті та здійснити
          акцепт даної пропозиції, тобто надати свою згоду на укладання Договору на умовах, викладених в даній оферті та
          у тексті самого Договору. АКЦЕПТОМ ДАНОЇ ОФЕРТИ (ПРОПОЗИЦІЇ) Є ПРИЙНЯТТЯ
          <router-link :to="{ name: 'contract' }">УМОВ ДОГОВОРУ</router-link> ШЛЯХОМ ОПЛАТИ ПОСЛУГ. З моменту оплати
          послуг фізична особа автоматично приймає умови Договору, і він вважається укладеним без його підписання у
          кожному окремому випадку. Вищевикладеним Виконавець виражає намір вважати себе зобов’язаним у разі прийняття
          пропозиції укласти Договір.
        </p>
        <p class="document_sub-p">
          За необхідності, на розсуд Виконавця та за його бажанням, не вступаючи у протиріччя з умовами даної оферти,
          Виконавець може укласти із фізичною особою Договір у формі письмового двостороннього документа.
        </p>
        <p class="document_sub-p">
          Для можливості укладання Договору та виконання зобов’язань за ним, фізична особа, яка бажає мати повний доступ
          до системи «Dentabula», повинна шляхом заповнення відповідної електронної форми на офіційному сайті системи
          «Dentabula» надати Виконавцю наступні відомості про себе:
          <br />
          - унікальне ім’я (логін);
          <br />
          - адреса власної електронної пошти.
        </p>
        <p class="document_sub-p">
          <b>УВАГА!</b> Для коректної роботи системи «Dentabula», фізична особа, яка бажає мати до неї повний доступ,
          повинна самостійно забезпечити себе наступним апаратно-програмним середовищем:
        </p>
        <p class="document_sub-list">
          <b>- операційна система:</b> Windows XP або вище, Linux, Mac OS Х 10.0 або вище;
        </p>
        <p class="document_sub-list"><b>- процесор:</b> 900 MHz або вище;</p>
        <p class="document_sub-list"><b>- оперативна пам’ять:</b> 1 Gb або вище;</p>
        <p class="document_sub-list"><b>- відео-карта:</b> VGA 800x600 або вище;</p>
        <p class="document_sub-list"><b>- версія OpenGL:</b> 2.0 або вище;</p>
        <p class="document_sub-list">
          <b>- браузер:</b> IE v.11 або вище, Edge v.15 або вище, Firefox v.56 або вище, Chrome v.49 або вище, Safari
          v.10 або вище, iOS Safari v.10 або вище.
        </p>
        <p class="document_sub-list">
          <b>- мережа Інтернет:</b> безперервний та безперебійний доступ до мережі Інтернет, зі швидкістю передачі даних
          не менше ніж 512 kb/s.
        </p>

        <p class="document_sub-p">
          Якщо фізична особа не згодна із умовами даної пропозиції (оферти), та умовами Договору, Виконавець просить
          таку фізичну особу не акцептувати їх (тобто не приймати), та не здійснювати оплату послуг з повного доступу до
          системи «Dentabula». В разі акцепту фізичною особою (прийняття) умов Договору, шляхом здійснення оплати послуг
          з повного доступу до системи «Dentabula», це означає, що така фізична особа повністю ознайомлена з умовами
          Договору, розуміє та приймає їх, а також погоджується із ними.
        </p>
        <p class="document_sub-p">
          <b>УВАГА!</b> Перед укладанням Договору, Виконавець надає фізичній особі, яка бажає мати повний доступ до
          системи «Dentabula», наступну інформацію:
        </p>
        <p class="document_sub-list">
          <b>- найменування Виконавця:</b> фізична особа-підприємець Погарський Антон Юрійович;
        </p>
        <p class="document_sub-list">
          <b
            >- дата та номер запису в Єдиному державному реєстрі юридичних осіб, фізичних осіб-підприємців та
            громадських формувань:</b
          >
          28.03.2018 року, № 21030000000096301;
        </p>
        <p class="document_sub-list">
          <b>- місцезнаходження Виконавця:</b> 69600, Україна, Запорізька область, місто Запоріжжя, Комунарський район,
          вул. Реконструктивна, будинок 3;
        </p>
        <p class="document_sub-list">
          <b>- порядок прийняття претензій:</b> претензії приймаються в письмовому вигляді, викладені українською,
          англійською або російською мовами, шляхом їх направлення на наступну адресу електронної пошти Виконавця:
          dentabula.AP@gmail.com. Претензія розглядається Виконавцем протягом 30 календарних днів з моменту її
          отримання. В разі, коли претензія є надуманою та/або необґрунтованою, Виконавець має право залишити її без
          розгляду та/або без відповіді;
        </p>
        <p class="document_sub-list">
          <b>- основні характеристики послуги:</b> доступ фізичної особи до всіх елементів системи «Dentabula», з
          можливістю завантаження, обробки, перегляду та виводу відповідних спеціалізованих комп’ютерних файлів, в межах
          наданого такою системою функціоналу;
        </p>
        <p class="document_sub-list">
          <b>- ціна (вартість) послуг:</b> вартість Послуг за Договором вказана на офіційному сайті системи «Dentabula»,
          за адресою: https://www.dentabula.com.;
        </p>
        <p class="document_sub-list"><b>- умови оплати:</b> визначені в умовах Договору;</p>
        <p class="document_sub-list">
          <b>- гарантійні зобов'язання:</b> будь-які гарантії та/або гарантійні зобов’язання з боку Виконавця не
          надаються та/або не встановлюються;
        </p>
        <p class="document_sub-list"><b>- умови виконання Договору:</b> визначені в умовах Договору;</p>
        <p class="document_sub-list">
          <b>- мінімальна тривалість Договору:</b> необмежена, Договір триває з моменту його укладання і до моменту його
          розірвання будь-якою стороною на визначених в його редакції умовах;
        </p>
        <p class="document_sub-list"><b>- період прийняття пропозицій:</b> необмежений;</p>
        <p class="document_sub-list"><b>- порядок розірвання Договору:</b> визначений в умовах Договору.</p>
      </div>
    </div>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";
// import translate from './translate.js'

export default {
  name: "PublicOffer",
  components: { mainMenu, mainFooter },
  data() {
    return {
      // translate: translate,
    };
  },
  computed: {
    currentLang() {
      return this.$store.getters.currentLang;
    },
  },
};
</script>

<style lang="sass">
#publicOffer
	.wrapper
		display: flex
		max-width: 1024px
		margin: 0 auto
		padding: 0 0.5rem
		min-height: calc(100vh - 280px)
		.document
			padding: 1rem 0 3rem
			.document_sub-heading
				text-align: center
				margin-top: 1rem
				margin-bottom: 1rem
				font-size: 1rem
				font-weight: bold
			.document_sub-title
				text-align: center
				margin-top: 1rem
				margin-bottom: 1rem
				font-weight: bold
			.document_sub-table
				width: 100%
				& tr:first-child
					& td
						padding-bottom: 0.5rem
			.document_sub-p
				text-indent: 2rem
				margin-bottom: 1rem
				text-align: justify
			.document_sub-list
				text-indent: 2rem
				text-align: justify
</style>
